body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus{
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar-thumb {
  background: #c6cacc;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 3px #ababab;
  border-radius: 10px; */
  background: transparent;
}
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #afb3b5;
}

input {
  width: 100%;
  display: block;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 12px;
  margin-top: 5px;
}
select {
  width: 100%;
  display: block;
  border-radius: 5px;
  /*border: 1px solid #ccc;*/
  padding: 12px;
  margin-top: 6px;
}
.rdw-editor-wrapper {
  margin-bottom: 10px;
}
/* button {
  max-width: 150px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
} */
.submit-btn {
  float: right;
}
.wrapper input[type="text"] {
  position: relative;
}

/* input { font-family: '
  '; } This is for the placeholder */

/* .wrapper:before {
    font-family: 'FontAwesome';
    color:red;
    position: relative;
    left: -10px;
    content: "\f007";
} */
.fa-facebook-square {
  color: #3b5998;
}
.fa-instagram {
  color: #3f729b;
}
.fa-linkedin-square {
  color: #0976b4;
}
.fa-twitter-square {
  color: #55acee;
}
.fa {
  display: block !important;
  font-size: medium !important;
}
.social {
  padding-left: 10px;
}

.input-wrapper input#linkedin {
  padding-left: 40px;
}
.input-wrapper input#facebook {
  padding-left: 40px;
}
.input-wrapper input#twitter {
  padding-left: 40px;
}
.input-wrapper input#instagram {
  padding-left: 40px;
}
.basic-single {
  margin-top: 6px;
}
.textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
}
.addbutton {
  padding: 0px;
  border-radius: 20px;
  /*background-color: white;*/
  /*color: #4747d2;*/
  /*border: 2px solid #4747d2;*/
}
.css-1pcexqc-container {
  position: relative;
  box-sizing: border-box;
  margin-top: 6px;
}
.previewComponent {
  margin-top: 6px;
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
  overflow: hidden;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  width: 35% !important;
  overflow: hidden;
  /*width: 100%;*/
  /*height: 100%;*/
}
.ant-calendar-picker {
  width: 100%;
}
.ant-calendar-picker-input,
.ant-time-picker-input {
  height: 50px !important;
  border: 1px solid #ccc !important;
  color: grey !important;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: black;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.public-DraftEditorPlaceholder-inner {
  color: #989292;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

